<template>
  <div>
    <p style="font-size: 32px; font-weight: bold; border-bottom: 1px solid lightgray;">
      {{ $t("ExchangeEmployees") }}
    </p>
    <div style="display: flex; flex-direction: row; justify-content: space-between">
      <div style="display: flex; flex-direction: row">
        <button
          v-b-modal.add-exchange-employee
          class="button"
          style="border-radius: 6px; height: 36px; margin-top: 25px; margin-right: 20px; width:240px"
        >
          <b-icon-plus />
          {{ $t("ExchangeEmployee") }}
        </button>
        <b-form-group
          id="input-group-9"
          :label="$t('ExchangeEmployeesSourceStore')"
          label-for="input-9"
          style="margin-bottom: 10px; width: 200px"
        >
          <b-form-select
            ref="storeSelect"
            v-model="store"
            :clearable="false"
            required
            :options="selectFiliale"
            style="width:100%;padding: 8.5px; color: #82868c; border-radius: 8px; border: none; box-shadow: 0px 0px 10px rgb(40 41 61 / 14%);"
            label="text"
            :value="storeId"
            placeholder="Select"
            aria-describedby="input-1-live-feedback"
            @search="onSearchM"
            @clear="clearSelection"
          />
        </b-form-group>
        <b-form-group
          id="input-group-9"
          :label="$t('ExchangeDestinationStore')"
          label-for="input-9"
          style="margin-bottom: 10px; width: 200px; margin-left: 10px"
        >
          <b-form-select
            ref="storeSelect"
            v-model="destination"
            :clearable="false"
            required
            style="width:100%;padding: 8.5px; color: #82868c; border-radius: 8px; border: none; box-shadow: 0px 0px 10px rgb(40 41 61 / 14%);"
            :options="selectFiliale"
            label="text"
            :value="storeId"
            placeholder="Select"
            aria-describedby="input-1-live-feedback"
            @search="onSearchM"
            @clear="clearSelection"
          />
        </b-form-group>
        <b-form-group
          id="input-group-9"
          :label="$t('ExchangeStartTime')"
          label-for="input-9"
          style="margin-bottom: 10px; margin-bottom: 10px;
          margin-left: 12px;
          margin-top: -5px;"
        >
          <div class="year">
            <button @click="previousDay">
              <b-icon-arrow-left />
            </button>
            <date-picker
              v-model="selectedDate"
              value-type="format"
              class="form-control"
              format="DD-MM-YYYY"
              type="date"
              placeholder="Select Date"
              style="width: 193px; border: none;"
              :lang="lang"
              :clearable="false"
            />
            <button
              style="margin-left: 5px"
              @click="nextDay"
            >
              <b-icon-arrow-right />
            </button>
          </div>
        </b-form-group>
      </div>

      <!-- <div style="display: flex; padding-right: 15px">
        <div class="search-toggle">
          <p>{{ $t("SearchBy") }}</p>
          <div>
            <p>
              {{ $t(`Employee Name`) }}
            </p>
          </div>
        </div>
        <div style="margin-top: 20px">
          <div class="black">
            <b-icon-search
              v-if="searchPlate == ''"
              class="search1"
              style="position: absolute; left: 5px; top: 10px"
            />
            <b-icon-x-circle
              v-else-if="searchPlate != '' && !noSearch"
              class="search1"
              style="position: absolute; margin-top: -6px; right: 10px; top: 17px; cursor: pointer"
              @click="searchPlate = ''"
            />
            <input
              v-model="searchPlate"
              type="text"
              autocomplete="off"
              name="search-box"
              :placeholder="$t(`Employee Name`)"
              :style="
                getFilterPlateTruck.length > 0 && noSearch
                  ? 'border-radius: 8px 8px 8px 8px'
                  : 'border-radius: 8px 8px 8px 8px; padding-left: 25px;'
              "
              @keyup="inputChanged"
              @keydown.down="onArrowDown"
              @keydown.up="onArrowUp"
            >
          </div>
          <div
            v-if="getFilterPlateTruck.length > 0 && noSearch"
            ref="scrollContainer"
            class="dropdrop"
            :style="getFilterPlateTruck.length > 7 ? 'height: 210px' : 'height: auto'"
          >
            <div
              v-for="(result, index) in getFilterPlateTruck"
              :key="index"
              ref="options"
              style="cursor: pointer"
              class="search-item"
              :class="{ 'is-active': index === arrowCounter }"
              @click="
                searchByOn(result.registrationPlate);
                noSearch = false;
              "
            >
              <p style="margin: 0">
                {{ result.registrationPlate }}
              </p>
            </div>
          </div>
        </div>
      </div> -->
    </div>
    <div style="width: 100%; padding-top: 16px; margin-top: -3px">
      <table
        class="team_table"
        style="width: 100% !important; box-shadow: 0px 7px 20px rgb(40 41 61 / 8%); text-align: left;"
      >
        <thead>
          <tr>
            <th style="padding: 10px; border-radius: 10px 0px 0px 0px">
              {{ $t("ExchangeEmployeesFullName") }}
            </th>
            <th style="padding: 10px;">
              {{ $t("EmployeeEmployeeT") }}
            </th>
            <th style="padding: 10px">
              {{ $t("ExchangeEmployeesSSName") }}
            </th>
            <th style="padding: 10px">
              {{ $t("ExchangeEmployeesDesName") }}
            </th>
            <th style="padding: 10px">
              {{ $t("ExchangeEmployeesStartDate") }}
            </th>
            <th style="padding: 10px">
              {{ $t("ExchangeEmployeesEndDate") }}
            </th>
            <!-- <th /> -->
            <!-- <th /> -->
            <th style="padding: 10px; border-radius: 0px 10px 0px 0px; text-align: left;">
              {{ $t("ExchangeEmployeesActions") }}
            </th>
          </tr>
        </thead>
        <tbody>
          <!-- getTrucksPagination getTrucksByStore -->
          <tr
            v-for="name in getExchangeEmployee"
            :key="name.sourceStoreId"
            style="border-bottom: 1px solid #dcdfe6; color: #606266; cursor: pointer"
            @click="matProps(filiale)"
          >
            <!-- :style="classNameCh === getFiliale.name ? 'background-color: #e8e8e8' : ''" -->

            <td style="padding: 10px">
              {{ name.fullName }}
            </td>
            <td
              v-if="name.employeeType === 'Internal'"
              style="padding: 9px"
            >
              {{ $t("Internal") }}
            </td>
            <td
              v-else
              style="padding: 9px"
            >
              {{ $t("External") }}
            </td>
            <td style="padding: 10px">
              {{ name.sourceStoreName }}
            </td>
            <td style="padding: 10px">
              {{ name.destinationStoreName }}
            </td>
            <td style="padding: 10px">
              {{ dateModified2(name.startDate) }}
            </td>
            <td style="padding: 10px">
              {{ dateModified2(name.endDate) }}
            </td>
            <!-- <td
              v-b-modal.edit-truck
              style="padding: 10px; text-align: left; cursor: pointer"
              @click="openEdit(name)"
            >
              <b-icon-pencil />
            </td>
            <td>
              <p
                v-b-modal.deactivate-truck
                class="p-0 m-0 ml-50"
                @click="getIDTruck(name.truckId), getRoutesPerTrucks()"
              >
                <toggle-button
                  :value="true"
                  :sync="true"
                  color="#242F6E"
                />
              </p>
            </td> -->
            <td style="text-align: left;">
              <p
                v-if="name.isCancelled"
                style="color: red"
              >
                {{ $t("AddNew9") }}
              </p>
              <b-button
                v-else
                v-b-modal.cancel-exchange-employee
                style="max-height: 35px; font-size: small; background: transparent linear-gradient(53deg, #0071AE 0%, #55B94A 100%) 0% 0% no-repeat padding-box;"
                @click="getIDTruck(name.employeeUserId), getExchangeEmployeedd(name.exchangeEmployeeId) ;getRoutesPerTrucks(name.destinationStoreGLN); getDestinationStoreId(name.destinationStoreId);"
              >
                {{ $t("Cancel") }}
              </b-button>
            </td>
          </tr>
        </tbody>
      </table>
      <!-- <div
        v-for="name in getTrucksPagination"
        :key="name"
      >
        <div cl class="registration-ui">{{ name.registrationPlate }}</span>
        </div>ass="codepen-wrapper">
          <span
      </div> -->
    </div>
    <div>
      <b-pagination
        v-model="pageNumber"
        :total-rows="getTotalItemsForEXUsers"
        :per-page="pageSize"
        first-number
        last-number
        align="right"
        prev-class="prev-item"
        next-class="next-item"
        class="mt-1 mb-0"
      >
        <template #prev-text>
          <span>{{ $t("Previous") }}</span>
        </template>
        <template #next-text>
          <span>{{ $t("Next") }}</span>
        </template>
      </b-pagination>

    </div>
    <AddTruckModal @click="addTruckk" />
    <EditTruckModal
      :edit-truck="truck"
      @edit="editTruck"
    />
    <DeactivateTruckModal
      :item="Id"
      @click="deactivateTruck"
    />
    <ActivateTruck
      :item="Id"
      @click="activateTruck"
    />
    <AssignStoreToTruck
      :truck-id="truckId"
      @assignFiliale="handleAssignStore"
    />
    <EditStoreToTrucks
      :truck-id="truckId"
      @assignFiliale="handleAssignStore"
    />
    <AddExchangeEmployeeModal
      @click="assignExchangeEmployee"
      @clickk="assignExchangeTruckToPrivateUser"
    />
    <CancelExchangeEmployeeModal
      :destination-id="destinationId"
      :item="Id"
      :exchange-employee-id="exchangeEmpoyeeId"
      :destination-store-gln="destinationStoreGln"
      @click="cancelExchangeEmployee"
    />
    <!-- <EditDressColorModal
      :color="color"
      :dress-name="materialId.name"
      @updateColor="editColor"
    /> -->
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
// eslint-disable-next-line no-unused-vars
import AddTruckModal from '@/components/trucks/modals/AddTruckModal.vue';
import EditTruckModal from '@/components/trucks/modals/EditTruckModal.vue';
import DeactivateTruckModal from '@/components/trucks/modals/DeactivateTruck.vue'
import AssignStoreToTruck from '@/components/trucks/modals/AssignStoreToTruck.vue'
import EditStoreToTrucks from '@/components/trucks/modals/EditStoreToTrucks.vue'
import AddExchangeEmployeeModal from '@/components/employees/modals/AddExchangeEmployeeModal.vue'
import CancelExchangeEmployeeModal from '@/components/employees/modals/CancelExchangeEmployeeModal.vue';
// import EditDressColorModal from '@/components/products/modals/EditDressColorModal.vue';
import moment from 'moment';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import ActivateTruck from '@/components/trucks/modals/ActivateTruck.vue'


export default {
  components: {
    AddTruckModal,
    EditTruckModal,
    DeactivateTruckModal,
    AssignStoreToTruck,
    EditStoreToTrucks,
    ActivateTruck,
    AddExchangeEmployeeModal,
    DatePicker,
    CancelExchangeEmployeeModal,
    // EditDressColorModal,
  },
  // eslint-disable-next-line vue/require-prop-types
  props: ['materialId', 'createOrder'],
  data() {
    return {
      initialId: '',
      store: 'ALL',
      destination: 'ALL',
      status: true,
      statuss: false,
      storeId: null,
      classNameCh: '',
      searchTerm: '',
      rows: [],
      materialObject: {},
      pageM: 1,
      selectedDate: moment().format('DD-MM-YYYY'),
      editMat: {},
      page: 1,
      isactive: true,
      exchangeEmpoyeeId: '',
      pageSize: 15,
      pageNumber: 1,
      Id: '',
      test: null,
      destinationId: null,
      storeGLN: null,
      noSearch: false,
      lang: {
        formatLocale: {
          firstDayOfWeek: 1,
        },
      },
      searchPlate: '',
      destinationStoreGln: '',
      arrowCounter: 0,
      truckId: '',
      enterPressed: false,
      truck: {
        truckId: '',
        brandName: '',
        modelName: '',
        variantName: '',
        registrationPlate: '',
        firstRegistration: '',
        insuranceExpirationDate: '',
        storeName: '',
      },
      color: {
        mainDressCategoryColorId: '',
        name: '',
      },
      getFiliale: {
        filialeName: 'Mercedes',
        address: 'Sprinter',
        todayRoutes: 'LU-2142131',
        trucks: '12/03/2018',
        totalEmployee: '12/03/2023',
      },
    };
  },

  computed: {
    ...mapGetters([
      'getDressColors',
      'getFiliales',
      'getTotalItemsForFiliales',
      'getTotalItemsForFiliales',
      'getCurrentPageForStore',
      'getTrucksPagination',
      'getTrucksByStore',
      'getFilialesForTrucks',
      'getFilterPlateTruck',
      'getTotalItemsForTrucks',
      'getRoutersForTrucks',
      'getExchangeEmployee',
      'getFilialesForRoutes',
      'getTotalItemsForUsers',
      'getTotalItemsForEXUsers',
    ]),
    selectFiliale() {
      const filiales = this.getFilialesForRoutes
        .filter((option) => { return option.storeGLN && option.storeName }) // Filter out objects without value or text
        .map((option) => {
          return {
            value: option.storeId,
            text: option.storeName,
          }
        });
      filiales.unshift({ value: 'ALL', text: 'ALL' });

      return filiales;
    },
  },
  watch: {
    pageNumber(value) {
      this.getExchangeEmployeePagination({
        fullName: null,
        sourceStoreId: null,
        destinationStoreId: null,
        startShift: null,
        pageNumber: value,
        pageSize: this.pageSize,
      })
    },
    store: {
      handler(value) {
        if (value == 'ALL') {
          this.getExchangeEmployeePagination({
            fullName: null,
            sourceStoreId: null,
            destinationStoreId: this.destination == 'ALL' ? null : this.destination,
            startShift: this.selectedDate,
            pageNumber: this.pageNumber,
            pageSize: this.pageSize,
          })
        } else {
          this.getExchangeEmployeePagination({
            fullName: null,
            sourceStoreId: value,
            destinationStoreId: this.destination == 'ALL' ? null : this.destination,
            startShift: this.selectedDate,
            pageNumber: this.pageNumber,
            pageSize: this.pageSize,
          })
        }
      },
      immediate: true,
    },
    destination: {
      handler(value) {
        if (value == 'ALL') {
          this.getExchangeEmployeePagination({
            fullName: null,
            sourceStoreId: this.store == 'ALL' ? null : this.store,
            destinationStoreId: null,
            startShift: this.selectedDate,
            pageNumber: this.pageNumber,
            pageSize: this.pageSize,
          })
        } else {
          this.getExchangeEmployeePagination({
            fullName: null,
            sourceStoreId: this.store == 'ALL' ? null : this.store,
            destinationStoreId: value,
            startShift: this.selectedDate,
            pageNumber: this.pageNumber,
            pageSize: this.pageSize,
          })
        }
      },
      immediate: true,
    },
    selectedDate: {
      handler(value) {
        this.getExchangeEmployeePagination({
          fullName: null,
          sourceStoreId: this.store == 'ALL' ? null : this.store,
          destinationStoreId: this.destination == 'ALL' ? null : this.destination,
          startShift: value,
          pageNumber: this.pageNumber,
          pageSize: this.pageSize,
        })
      },
      immediate: true,
    },
  },
  mounted() {
    this.fetchExchangeTrucks();
    this.fetchFiliale();
    // this.fetchFilialeForTrucks();
    this.fetchFilialeForExchangeTrucks();

    // this.fetchTruckByStore(this.store.storeId);
    // console.log('idd', this.getFiliales);
    // console.log('date', new Date().toJSON().slice(0, 10).replace(/-/g, '.'));
  },
  methods: {
    ...mapActions([
      'loadFilialePagination',
      'changeLoadingtoTrue',
      'loadFilteredStoreName',
      'addTruck',
      'loadTrucksPaginationWithoutStore',
      'loadTrucksByStore',
      'loadFilialePaginationForTrucks',
      'loadFilteredPlateTruck',
      'resetFilteredPlate',
      'editTruckById',
      'loadToursForTrucks',
      'deactivateAndActivateTruck',
      'assignTruckStore',
      'getExchangeEmployeePagination',
      'assignExhangeEmployeesToStore',
      'assignExchangeTruckStoreToPrivateuser',
      'loadFilialePaginationForRoutes',
      'cancelEXEmployee',
      'getTourNameByEmployeeUserIdStartAndEndShift',
    ]),
    async fetchExchangeTrucks() {
      try {
        await this.getExchangeEmployeePagination({
          fullName: null,
          sourceStoreId: null,
          destinationStoreId: null,
          startShift: this.selectedDate,
          pageNumber: this.pageNumber,
          pageSize: this.pageSize,
        }).then(() => {
          // this.changeLoadingtoTrue(true);
        });
      } catch (e) {
        console.log(e);
        //       // this.changeLoadingtoTrue(false);
      }
    },
    assignExchangeEmployee(object) {
      // console.log('object', object)
      this.assignExhangeEmployeesToStore({
        object,
        successCallback: () => {
          this.getExchangeEmployeePagination({
            fullName: null,
            sourceStoreId: null,
            destinationStoreId: null,
            startShift: this.selectedDate,
            pageNumber: this.pageNumber,
            pageSize: this.pageSize,
          })
        },
      })
    },
    async fetchFilialeForExchangeTrucks() {
      try {
        //       // this.changeLoadingtoTrue(false);


        await this.loadFilialePaginationForRoutes({
          status: true,
          pageSize: 100,
          pageNumber: this.pageNumber,
        })
      } catch (e) {
        // console.log(e);
        //       // this.changeLoadingtoTrue(false);

      }
    },
    assignExchangeTruckToPrivateUser(object) {
      this.assignExchangeTruckStoreToPrivateuser({
        object,
        successCallback: () => {
          this.getExchangeEmployeePagination({
            fullName: null,
            sourceStoreId: null,
            destinationStoreId: null,
            startShift: null,
            pageNumber: this.pageNumber,
            pageSize: this.pageSize,
          })
        },
      })
    },
    async cancelExchangeEmployee(value) {
      // console.log('123', value)
      await this.cancelEXEmployee({
        exchangeEmployee: value,
        successCallback: () => {
          this.getExchangeEmployeePagination({
            fullName: null,
            sourceStoreId: null,
            destinationStoreId: null,
            startShift: null,
            pageNumber: this.pageNumber,
            pageSize: this.pageSize,
          })
        },
      })
    },
    getRoutesPerTrucks(value) {
      this.getTourNameByEmployeeUserIdStartAndEndShift({
        employeeUserId: this.Id,
        destinationStoreGLN: value,
        startShift: this.dateModified(new Date().toJSON().slice(0, 10).replace(/-/g, '.')),
        endshift: null,
      })
    },
    getIDTruck(value) {
      // console.log('ID', value)
      this.Id = value
      // return value
    },
    getExchangeEmployeedd(value) {
      this.exchangeEmpoyeeId = value
    },
    getDestinationStoreGLN(value) {
      this.destinationStoreGln = value
    },
    getDestinationStoreId(value) {
      this.destinationId = value
    },
    async loadFreeAssignedFiliales() {
      await this.loadFilialePagination({
        status: this.status,
        pageSize: 2,
        pageNumber: this.pageNumber,
      })
    },
    async fetchFiliale() {
      try {
        //       // this.changeLoadingtoTrue(false);


        await this.loadFilialePagination({
          status: this.status,
          pageSize: 100,
          pageNumber: 1,
        }).then(() => {
          // this.changeLoadingtoTrue(true);
        });
      } catch (e) {
        console.log(e);
        //       // this.changeLoadingtoTrue(false);
      }
    },
    async handleAssignStore(obj) {
      await this.assignTruckStore({
        object: obj,
        successCallback: () => {
          this.loadTrucksPaginationWithoutStore({
            status: this.status,
            pageNumber: this.pageNumber,
            pageSize: this.pageSize,
          });
          // this.freeTeamLeaders();
        },
      });
    },
    async filiale() {
      try {
        await this.loadUsersFullNamesById('TeamLeader').then(() => {
          // console.log('eeeee', res);
        });
      } catch (e) {
        console.log(e);
      }
    },
    // getIDTruck(value) {
    //   this.Id = value
    // },
    async fetchFilialeForTrucks(value) {
      try {
        //       // this.changeLoadingtoTrue(false);


        await this.loadFilialePaginationForTrucks({
          storeId: value,
          status: this.status,
          pageSize: 100,
          pageNumber: this.pageNumber,
        }).then(() => {
          // this.changeLoadingtoTrue(true);
        });
      } catch (e) {
        console.log(e);
        //       // this.changeLoadingtoTrue(false);
      }
    },
    async deactivateTruck(value) {
      // console.log('1235', value)
      try {
        await this.deactivateAndActivateTruck({
          truckId: value.truckId,
          status: value.status,
          successCallback: () => {
            this.loadTrucksPaginationWithoutStore({
              status: this.status,
              pageNumber: this.pageNumber,
              pageSize: this.pageSize,
            });
          // this.freeTeamLeaders();
          },
        }).then(() => {
        });
      } catch (e) {
        console.log(e);
      }
    },
    async activateTruck(value) {
      // console.log('ert', value)
      // console.log(value)
      try {
        await this.deactivateAndActivateTruck({
          truckId: value.truckId,
          status: true,
          successCallback: () => {
            this.loadTrucksPaginationWithoutStore({
              status: this.status,
              pageNumber: this.pageNumber,
              pageSize: this.pageSize,
            });
          // this.freeTeamLeaders();
          },
        }).then(() => {
        });
      } catch (e) {
        console.log(e);
      }
    },



    async addTruckk(obj) {
      await this.addTruck({
        object: obj,
        successCallback: () => {
          this.loadTrucksPaginationWithoutStore({
            status: this.status,
            pageNumber: this.pageNumber,
            pageSize: this.pageSize,
          }).then((res) => {
            console.log(res);
          });
        },
      });
    },
    async handleAssignFoliale(obj) {
      await this.assignTeamLeader({
        object: obj,
        successCallback: () => {
          this.loadTrucksPaginationWithoutStore({
            status: this.status,
            pageNumber: this.pageNumber,
            pageSize: this.pageSize,
          })
          // this.freeTeamLeaders();
        },
      });
    },

    async editTruck(obj) {
      // console.log('rreew', obj);
      await this.editTruckById({
        object: obj,
        successCallback: () => {
          this.loadTrucksPaginationWithoutStore({
            storeId: null,
            status: this.status,
            pageNumber: this.pageNumber,
            pageSize: this.pageSize,
          });
        },
      });
    },
    async activeF() {
      this.isactive = !this.isactive;
      this.searchUser = '';
      if (!this.isactive) {
        await this.loadTrucksPaginationWithoutStore({
          status: this.statuss,

          pageNumber: this.pageNumber,
          pageSize: this.pageSize,
        });
      } else {
        await this.loadTrucksPaginationWithoutStore({
          status: this.status,

          pageNumber: this.pageNumber,
          pageSize: this.pageSize,
        });
      }
    },

    // statusFalse() {
    //   // eslint-disable-next-line no-unused-expressions
    //   this.statuss = false;
    // },
    openEdit(value) {
      this.truck.truckId = value.truckId;
      this.truck.brandName = value.brandName;
      this.truck.modelName = value.modelName;
      this.truck.variantName = value.variantName;
      this.truck.registrationPlate = value.registrationPlate;
      this.truck.firstRegistration = value.firstRegistration;
      this.truck.insuranceExpirationDate = value.insuranceExpirationDate;
      this.truck.storeName = value.storeName;
    },
    edit(item) {
      this.color.mainDressCategoryColorId = item.mainDressCategoryColorId;
      this.color.name = item.colorName;
      this.$bvModal.show('modal-edit-color');
    },
    toggleBtn(truee) {
      this.sync = truee;
    },
    async editColor(obj) {
      await this.editColorToMainDressCategoryById({
        object: obj,
        successCallback: () => {
          this.loadDressColorsById(this.materialId.id);
        },
      });
    },
    async addColor(obj) {
      obj.mainDressCategoryId = this.materialId.id;
      await this.addColorToMainDressCategoryById({
        object: obj,
        successCallback: () => {
          this.loadDressColorsById(this.materialId.id);
        },
      });
    },
    matProps(color) {
      this.resetProductsByArticleNumber();
      this.$emit('click', color);
      this.classNameCh = color.colorName;
      this.inventoryProductSizeFromColorId(color.mainDressCategoryColorId);
    },
    async getMVbyMeter(page, pageS) {
      // const { data } =
      await this.loadFilialePagination({
        status: this.status,
        pageNumber: page,
        pageSize: pageS,
      });
    },
    nextMeter() {
      if (this.pageM < this.getTotalItemsForFiliales) {
        this.pageM += 1;
        this.getMVbyMeter(this.pageM, this.pageSize);
      }
    },
    prevMeter() {
      if (this.pageM > 1) {
        this.pageM -= 1;
        this.getMVbyMeter(this.pageM, this.pageSize);
      }
    },
    async onSearchM(query) {
      await this.loadFilteredStoreName({
        name: query,
      });
      if (query == '') {
        this.testResetMV();
        this.getMVbyMeter(this.getCurrentPageForStore, this.pageSize);
      }
    },
    onArrowDown(ev) {
      ev.preventDefault();
      if (this.arrowCounter < this.getFilterPlateTruck.length - 1) {
        this.arrowCounter += 1;
        this.fixScrolling();
      }
    },

    onArrowUp(ev) {
      ev.preventDefault();
      if (this.arrowCounter > 0) {
        this.arrowCounter -= 1;
        this.fixScrolling();
      }
    },

    inputChanged(event) {
      if (event.code == 'ArrowUp' || event.code == 'ArrowDown') {
        return;
      }
      // this.getFilteredActiveUsers = [];

      // eslint-disable-next-line no-useless-return
      if (event.code == 'Enter') {
        this.enterPressed = true;
        this.searchByOn(this.getFilterPlateTruck[this.arrowCounter]);
        this.removeLists();
        this.arrowCounter = 0;
        setTimeout(() => {
          this.enterPressed = false;
        }, 1000);
        // eslint-disable-next-line no-useless-return
        return;
      }
    },
    matPropss(color) {
      this.truckId = color;
    },
    fixScrolling() {
      const liH = this.$refs.options[this.arrowCounter].clientHeight;
      // console.log(liH);
      this.$refs.scrollContainer.scrollTop = liH * this.arrowCounter;
    },
    async searchByOn(value) {
      // console.log('vall', value)
      this.searchFiliale = value;
      this.noSearch = false;
      await this.loadTrucksPaginationWithoutStore({
        storeId: null,
        registrationPlate: value,
        status: this.status,
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
      });
    },
    removeLists() {
      this.noSearch = false;
      this.resetFilteredPlate();
    },
    async clearSelection() {
      try {
        //       // this.changeLoadingtoTrue(false);


        await this.loadTrucksPaginationWithoutStore({
          storeId: null,
          status: this.status,
          pageNumber: this.pageNumber,
          pageSize: this.pageSize,
        }).then(() => {
          // this.changeLoadingtoTrue(true);
        });
      } catch (e) {
        console.log(e);
        //       // this.changeLoadingtoTrue(false);
      }
      // this.store = null;
    },
    resetValue() {
      this.$refs.storeSelect.reset();
    },
    dateModified(xdate) {
      // return moment(xdate).format('DD/MM/YYYY - HH:mm');
      return moment(xdate).format('YYYY-MM-DD HH:mm');
    },
    dateModified2(xdate) {
      // return moment.utc(xdate).format('DD/MM/YYYY - HH-mm');
      return moment(xdate).format('DD-MM-YYYY HH:mm');
    },
    previousDay() {
      this.selectedDate = moment(this.selectedDate, 'DD-MM-YYYY').subtract(1, 'days').format('DD-MM-YYYY');
    },
    nextDay() {
      this.selectedDate = moment(this.selectedDate, 'DD-MM-YYYY').add(0.5, 'days').format('DD-MM-YYYY');
    },
  },
};
</script>

<style scoped lang="scss">
// just stuff to make the pen not look terrible

h1 {
  margin: 2em 0 0;
  font-size: 2em;
  font-family: helvetica, ariel, sans-serif;
  text-align: left;
}

h2 {
  margin: 1em 0 2em;
  font-size: 1.4em;
  font-family: helvetica, ariel, sans-serif;
  text-align: left;
}

span{
  color: black;
}
p{
  color: black;
}
table td{
  color: black;
}

.codepen-wrapper {
  width: 25%;
  margin: 0 37.5%;
}

// this is the stuff you want.

.registration-ui {
  background: linear-gradient(to bottom, #f8d038 0%, #f5ca2e 100%);
  padding: 0.25em 1em 0.25em 1.75em;
  font-weight: bold;
  font-size: 2em;
  border-radius: 5px;
  border: 1px solid #000;
  box-shadow: 1px 1px 1px #ddd;
  position: relative;
  font-family: helvetica, ariel, sans-serif;
}

.registration-ui:before {
  content: "GB";
  display: block;
  width: 30px;
  height: 100%;
  background: #063298;
  position: absolute;
  top: 0;
  border-radius: 5px 0 0 5px;
  color: #f8d038;
  font-size: 0.5em;
  line-height: 85px;
  padding-left: 5px;
}

.registration-ui:after {
  content: "";
  display: block;
  position: absolute;
  top: 7px;
  left: 5px;
  width: 20px;
  height: 20px;
  border-radius: 30px;
  border: 1px dashed #f8d038;
}
.search-toggle {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.color-listing__wrapper {
  .mats-listing {
    margin-top: 0;
  }
  .table__search-input {
    visibility: hidden;
  }
  input {
    width: 100px;
  }
}
.button {
  margin-bottom: 15px !important;
  width: 195px;
}
.mat {
  display: flex;
  justify-content: space-between;
  align-content: center;
  &.active {
    background-color: #e8e8e8;
  }
  div {
    display: flex;
    justify-content: space-between;
    align-content: center;
  }
  span {
    margin-left: 10px;
    svg {
      font-size: 15px;
    }
  }
}
.team_table {
  font-size: 12px;
  border-radius: 8px 8px 8px 8px !important;
}

tr:last-child {
  border-bottom: none !important;
}
@media screen and (max-width: 1823px) {
  .color-listing__wrapper2 {
    width: 200px;
    button {
      width: 90%;
    }
    .team_table {
      width: 150px !important;
    }
  }
}
@media screen and (max-width: 1400px) {
  .color-listing__wrapper {
    margin-left: 50px;
    width: 150px !important;
    .team_table {
      width: 150px !important;
    }
  }
  .color-listing__wrapper2 {
    margin-left: 10px;
    width: 200px !important;
    button {
      width: 150px;
      font-size: 13px;
      height: 36px;
    }
    .team_table {
      width: 100% !important;
    }
  }
}
@media screen and (max-width: 896px) {
  .color-listing__wrapper {
    margin-left: 0 !important;
    flex-basis: 49%;
  }
}
.color-listing__wrapper {
  .mats-listing {
    margin-top: 0;
  }
  .table__search-input {
    visibility: hidden;
  }
  input {
    width: 100px;
  }
}
.button {
  margin-bottom: 15px !important;
  width: 195px;
}
.mat {
  display: flex;
  justify-content: space-between;
  align-content: center;
  &.active {
    background-color: #e8e8e8;
  }
  div {
    display: flex;
    justify-content: space-between;
    align-content: center;
  }
  span {
    margin-left: 10px;
    svg {
      font-size: 15px;
    }
  }
}
.team_table {
  font-size: 12px;
  border-radius: 8px 8px 8px 8px !important;
}

tr:last-child {
  border-bottom: none !important;
}
@media screen and (max-width: 1823px) {
  .color-listing__wrapper2 {
    width: 200px;
    button {
      width: 90%;
    }
    .team_table {
      width: 150px !important;
    }
  }
}
@media screen and (max-width: 1400px) {
  .color-listing__wrapper {
    margin-left: 50px;
    width: 150px !important;
    .team_table {
      width: 150px !important;
    }
  }
  .color-listing__wrapper2 {
    margin-left: 10px;
    width: 200px !important;
    button {
      width: 150px;
      font-size: 13px;
      height: 36px;
    }
    .team_table {
      width: 100% !important;
    }
  }
}
.search1 {
  padding: 0px;
}
.black {
  position: relative;
}
.black input {
  border: none;
  box-shadow: rgb(40 41 61 / 14%) -1px 4px 10px;
  height: 36px;
}
.dropdrop::-webkit-scrollbar {
  display: none;
}
.dropdrop {
  display: inline-block;
  overflow: auto;
  position: absolute;
  background: white;
  width: 227px;
  box-shadow: rgb(40 41 61 / 14%) -1px 4px 10px;
}
.search-item {
  padding: 5px;
  max-width: 500px;
  width: 100%;
  font-weight: 600;
  color: #82868c;
  font-size: 1rem;
  border-bottom: 1px solid #e8e8e8;
}
.search-item:last-child {
  border-bottom: none;
}
.search-item:hover {
  background: #82868c;
  color: white;
}
.search-item hr {
  color: lightgray;
  border-top: none;
  margin: -1.3rem 0 0.5rem 0;
}
.is-active {
  background-color: #dedede;
}
@media screen and (max-width: 896px) {
  .color-listing__wrapper {
    margin-left: 0 !important;
    flex-basis: 49%;
  }
}
.button {
  width: 195px;
}
.addEmployeeButton {
  width: 210px;
}

@media screen and (max-width: 1600px) {
  .scroll {
    overflow-x: scroll;
  }
}

@media screen and (max-width: 1110px) {
  .button {
    margin-right: 10px;
    font-size: 12px;
    height: 36px;
    width: 150px;
  }
}

.dropdrop::-webkit-scrollbar {
  display: none;
}

.dropdrop {
  display: inline-block;
  overflow: auto;
  position: absolute;
  background: white;
  width: 227px;
  box-shadow: rgb(40 41 61 / 14%) -1px 4px 10px;
  z-index: 3;
}

input[name="search-box"] {
  display: block;
  max-width: 500px;
  width: 95%;
  padding: 0.5rem 1rem;
  padding-left: 25px;
}

.black {
  position: relative;
}

.black input {
  border: none;
  box-shadow: rgb(40 41 61 / 14%) -1px 4px 10px;
}

.search-item {
  padding: 5px;
  max-width: 500px;
  width: 100%;
  font-weight: 600;
  color: #82868c;
  font-size: 1rem;
  border-bottom: 1px solid #e8e8e8;
}

.search-item:last-child {
  border-bottom: none;
}

.search-item:hover {
  background: #82868c;
  color: white;
}

.search-item hr {
  color: lightgray;
  border-top: none;
  margin: -1.3rem 0 0.5rem 0;
}

@media screen and (max-width: 763px) {
  .con {
    flex-wrap: wrap;
  }

  .active-inactive-con {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }

  .button {
    height: auto;
  }
}

.is-active {
  background-color: #dedede;
}
.year {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  // margin-left: 15px;
  //   margin-top: -7px;
  button {
    width: 36px !important;
    height: 36px;
    display: flex;
    margin-right: 5px;
    border-radius: 6px !important;
    justify-content: center;
    border: #c3d0dd 1px solid;
    background-color: #e9ecef;
    align-items: center;
  }
  h5 {
    border: #b4b4b4 2px solid;
    border-radius: 6px;
    background-color: whitesmoke;
    width: 40%;
    height: 36px;
    text-align: left;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px;
  }
}
</style>
